.trip-type .card {
  border: none;
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
}
.trip-type .list-group-item:hover {
  filter: brightness(95%);
  cursor: pointer;
}
.trip-type .list-group-item.active {
  background-color: #0272bc;
}