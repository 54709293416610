.table {
    color: #fff;
}
.table th{
    font-family: 'Gotham-Bold';
    text-transform: uppercase;
    color: rgb(156,123,165);
}
.green {
    color: rgb(147,201,178);
}
.discord iframe {
    width:100%;
    height:500px;
    border-radius:5px;
    border:0;
}