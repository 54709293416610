.trip-location .images {
  animation: showContent 1s ease-in-out;
  padding: 25px;
  padding-bottom: 0;
}
@keyframes showContent {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.trip-location .images .row {
  justify-content: center;
  align-items: center;
}
.trip-location .images button {
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: #212529;
}
.trip-location .images button:hover,
.trip-location .images button:focus {
  transform: scale(1.1, 1.1);
  color: #212529;
  text-decoration: none;
}
.trip-location button::after {
  content: '';
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 100%;
  opacity: 0;
  border-radius: 5px;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}
.trip-location button:hover::after {
  opacity: 1;
}
.trip-location .images button.active {
  transform: scale(1.1, 1.1);
  box-shadow: 0 1px 6px rgba(0, 0, 0, 0.12), 0 1px 4px rgba(0, 0, 0, 0.12);
}
.trip-location .images p {
  margin-bottom: 0;
  padding-top: 7px;
}
.trip-location .images .img-col {
  display: flex;
  justify-content: center;
}
.trip-location .images button {
  margin-bottom: 2rem;
}
.trip-location .images img {
  max-height: 170px;
  min-height: 170px;
}
.trip-location .images [alt='San José'] {
  margin-bottom: 0;
}
.trip-location .images [alt='Oakland'] {
  max-height: 140px;
  min-height: 140px;
}
.trip-location .location-selected {
  color: #495057;
  padding-left: 0.5rem;
  padding-top: 1rem;
  margin-bottom: 1rem;
}
.trip-location .location-selected h5 {
  padding-bottom: 1rem;
}
.trip-location .location {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  color: #495057;
  margin-bottom: 1rem;
  display: flex;
  justify-content: space-between;
}
.trip-location .location:last-of-type {
  margin-bottom: 1rem;
}
.trip-location h5,
.trip-location h6 {
  margin-bottom: 0;
}
.trip-location h6 {
  margin-top: 0.2rem;
}
.trip-location .location button {
  margin-right: 1rem;
}

@media (min-width: 375px) {
}
@media (min-width: 425px) {
}
@media (min-width: 576px) {
}
@media (min-width: 768px) {
  .client-type .modal-body {
    max-width: 525px;
    margin-left: auto;
    margin-right: auto;
  }
}
@media (min-width: 992px) {
}
@media (min-width: 1200px) {
  .client-type .modal-body {
    max-width: 700px;
  }
}
@media (min-width: 1440px) {
  .client-type .modal-body {
    max-width: 800px;
  }
}
