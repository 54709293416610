.footer {
  background: #0272bc;
  padding-top: 50px;
  padding-bottom: 50px;
}
.footer .logo-col {
  padding-bottom: 25px;
}
.footer .links-col {
  padding-bottom: 25px;
}
.footer .links-col button {
  color: white;
  text-decoration: none;
  padding: 0;
}
.footer .links-col button:hover {
  filter: brightness(90%);
}
@media (min-width: 375px) {
}
@media (min-width: 425px) {
}
@media (min-width: 576px) {
  .footer .btn-col {
    text-align: center;
  }
}
@media (min-width: 768px) {
  .footer .links-col button {
    padding-bottom: 25px;
  }
  .footer {
    padding-top: 100px;
    padding-bottom: 50px;
  }
  .footer button {
    font-size: 18px;
    line-height: 18px;
  }
  .footer button.btn-white {
    font-size: 20px;
    line-height: 20px;
  }
}
@media (min-width: 992px) {
  .footer {
  }
}
@media (min-width: 1200px) {
  .footer .logo-col img {
    position: relative;
    top: -12px;
  }
  .footer .links-col {
    margin-top: auto;
    margin-bottom: auto;
  }
}
@media (min-width: 1500px) {
}
