.services {
  margin-top: 100px;
  padding-bottom: 75px;
}
.services .title {
  margin-bottom: 15px;
  text-align: center;
}
.services h1,
.services h5,
.services .terms-conditions p {
  font-weight: bold;
}
.services .service {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}
.services .service img {
  margin-top: 3rem;
  margin-bottom: 2rem;
}
.services .service h5 {
  margin-bottom: 2rem;
}
.services .terms-conditions {
  margin-top: 50px;
  text-align: center;
}
.services .terms-conditions p {
  font-size: 1.25rem;
  margin: 0;
}

@media (min-width: 375px) {
}
@media (min-width: 425px) {
}
@media (min-width: 576px) {
}
@media (min-width: 768px) {
  .services h1 {
    font-size: 48px;
    line-height: 48px;
  }
  .services h5 {
    font-size: 22px;
    line-height: 22px;
    letter-spacing: -0.48px;
  }
  .services .service p {
    font-size: 16px;
    line-height: 16px;
    letter-spacing: -0.35px;
  }
  .services p {
    font-size: 22px;
    line-height: 22px;
    letter-spacing: -0.48px;
  }
}
@media (min-width: 992px) {
}
@media (min-width: 1200px) {
  .services.container {
    max-width: 1200px;
  }
  .services .service {
    padding-right: 40px;
    padding-left: 40px;
  }
}
@media (min-width: 1500px) {
}
