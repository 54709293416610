.details .form-label {
  color: #333333;
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.details input {
  border: none;
  padding-left: 0;
  color: #666666;
}
.details .contact-info input {
  border-bottom: #808080 1px solid;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.details .contact-info input::placeholder,
.details .type-of-event-input input::placeholder {
  color: rgba(132, 119, 119, 0.5);
}
.details .inputs-border {
  margin-bottom: 2rem;
}
@media (min-width: 375px) {
}
@media (min-width: 425px) {
}
@media (min-width: 576px) {
}
@media (min-width: 768px) {
  .details .form-label {
    font-size: 18px;
    line-height: 18px;
    letter-spacing: .1px;
  }
  .details input {
    font-size: 22px;
    line-height: 22px;
    letter-spacing: .22px;
  }
  .details .modal-body {
    max-width: 550px;
    margin-left: auto;
    margin-right: auto;
  }
}
@media (min-width: 992px) {
  .details .modal-body {
    max-width: 650px;
  }
}
@media (min-width: 1200px) {
  .details .modal-body {
    max-width: 750px;
  }
}
@media (min-width: 1440px) {
  .details .modal-body {
    max-width: 850px;
  }
}
