.client-type .cards-form-group {
  margin-bottom: 2rem;
  margin-top: 2rem;
}
.client-type .card {
  border: none;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}
.client-type .card:hover {
  transform: scale(1.05, 1.05);
}
.client-type .card::after {
  content: '';
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 100%;
  opacity: 0;
  border-radius: 5px;
  opacity: 0;
  transition: opacity .3s ease-in-out;
}
.client-type .card:hover::after {
  opacity: 1;
}
.client-type .card.selected {
  transform: scale(1.05, 1.05);
  box-shadow: 0 1px 6px rgba(0, 0, 0, 0.12), 0 1px 4px rgba(0, 0, 0, 0.12);
}
.client-type .card-body {
  padding: 0;
  margin-top: 7px;
  margin-left: 7px;
  margin-right: 7px;
  border-top-style: solid;
  border-top-color: #0272bc;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
}
.client-type .cards-form-group button {
  padding: 1.25rem;
  width: 100%;
  height: 100%;
  color: #333333;
}
.client-type .cards-form-group button:hover,
.client-type .cards-form-group button:focus {
  text-decoration: none;
}
.client-type .cards-form-group img {
  min-width: 100%;
  max-height: 100px;
}
@media (min-width: 375px) {
}
@media (min-width: 425px) {
}
@media (min-width: 576px) {
}
@media (min-width: 768px) {
  .client-type .modal-body {
    max-width: 525px;
    margin-left: auto;
    margin-right: auto;
  }
}
@media (min-width: 992px) {
}
@media (min-width: 1200px) {
  .client-type .modal-body {
    max-width: 700px;
  }
}
@media (min-width: 1440px) {
  .client-type .modal-body {
    max-width: 800px;
  }
}
