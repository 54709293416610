.hero {
  min-width: 100vw;
  color: white;
  text-align: center;
  position: absolute;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  height: calc(61vh + 300px);
  min-width: 100%;
  z-index: 10;
}
.hero h1,
.hero p {
  margin-bottom: 2rem;
  font-weight: bold;
  margin-left: auto;
  margin-right: auto;
}
@media (min-width: 375px) {
}
@media (min-width: 425px) {
}
@media (min-width: 576px) {
}
@media (min-width: 768px) {
  .hero h1 {
    font-size: 48px;
    line-height: 48px;
  }
  .hero p {
    font-size: 24px;
    line-height: 34px;
  }
}
@media (min-width: 992px) {
  .hero p {
    max-width: 700px;
    margin-left: auto;
    margin-right: auto;
  }
}
@media (min-width: 1200px) {
  .hero {
  }
}
@media (min-width: 1500px) {
  .hero {
    height: calc(61vh + 500px);
  }
}
@media (min-width: 2000px) {
}
