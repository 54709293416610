.clients {
  padding-top: 75px;
  padding-bottom: 75px;
}
.clients h1 {
  text-align: center;
  padding-bottom: 50px;
}
.clients .img-container {
  padding-bottom: 50px;
  display: flex;
  flex-direction: column;
  margin: auto;
}
/* Scoped to this class */
.clients .image-with-text .text-col {
  position: relative;
  top: 50px;
  /* To compensate for pushing content down */
  padding-bottom: 50px;
}
.clients p {
  font-weight: 200;
}
.clients blockquote {
  font-weight: bold;
}
.clients .image-with-text .text-col:before {
  content: '\201F';
  position: absolute;
  top: -15px;
  font-size: 300px;
  line-height: 300px;
  color: #0272bc;
  opacity: 0.2;
}
.clients .image-with-text .img-col {
}
.clients .image-with-text .img-col img {
  border-radius: 50%;
  /* temporary */
  border: 1px lightgray solid;
}
.clients .slick-dots {
  bottom: -75px;
}
@media (min-width: 375px) {
  .clients .image-with-text .img-col img {
    width: 75%;
  }
}
@media (min-width: 425px) {
}
@media (min-width: 576px) {
}
@media (min-width: 768px) {
  .clients h1 {
    font-size: 48px;
    line-height: 48px;
  }
  .clients p {
    font-size: 27px;
    line-height: 27px;
    letter-spacing: -0.59px;
  }
  .clients blockquote {
    font-size: 22px;
    line-height: 22px;
    letter-spacing: -0.48px;
  }
  .clients .img-container {
    flex-direction: row;
  }
  .clients .img-container img {
    padding-bottom: 0;
    padding-right: 15px;
  }
  .clients .img-container img:last-child {
    padding-right: 0;
  }
  .clients .image-with-text .text-col {
    margin-left: 100px;
  }
  .clients .image-with-text .text-col:before {
    left: -60px;
    top: -45px;
  }
  .clients .image-with-text .img-col {
    /* Compensation for above code */
    margin-left: -100px;
  }
  .clients .image-with-text .img-col img {
    width: 50%;
  }
}
@media (min-width: 992px) {
  .clients p {
    max-width: 450px;
  }
  .clients .img-container img {
    padding-right: 25px;
  }
}
@media (min-width: 1200px) {
  .clients .img-container img {
    padding-right: 50px;
  }
}
@media (min-width: 1500px) {
  .clients .img-container img {
    padding-right: 75px;
  }
}
