/* Particular to section (fleet and clients) css is edited in either Fleet.css or Clients.css */
.image-with-text h3 {
  margin-bottom: 2rem;
  font-weight: bold;
}
.image-with-text .img-col {
  display: flex;
  justify-content: center;
  align-items: center;
}
.image-with-text img {
  width: 100%;
}
.image-with-text button {
  width: 100%;
}
.image-with-text .start-quote-margin {
  margin-bottom: 1rem;
}
@media (min-width: 375px) {
}
@media (min-width: 425px) {
}
@media (min-width: 576px) {
}
@media (min-width: 768px) {
  .fleet h3 {
    font-size: 22px;
    line-height: 22px;
    letter-spacing: -0.48px;
  }
  .fleet p {
    font-size: 16px;
    line-height: 16px;
    letter-spacing: -0.35px;
    margin-bottom: 25px;
  }
}
@media (min-width: 992px) {
  .image-with-text img {
    width: 95%;
  }
}
@media (min-width: 1200px) {
}
@media (min-width: 1500px) {
}
