.table {
    color: #fff;
}
.table th{
    font-family: 'Gotham-Bold';
    text-transform: uppercase;
    color: rgb(156,123,165);
    background: rgba(255,255,255,0.05);
    border-top: 1px solid rgba(255,255,255,.2) !important;
}
.table td {
    border-top: 1px solid rgba(255,255,255,.2) !important;
}
.avatar-container {
    text-align: center;
}