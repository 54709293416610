@font-face {
  font-family: 'Gotham-Light';
  src: url('/fonts/gotham-light.otf') format('opentype');
  font-weight: 200;
  font-style: normal;
}
@font-face {
  font-family: 'Gotham-Medium';
  src: url('/fonts/gotham-medium.otf') format('opentype');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Gotham-Ultra';
  src: url('/fonts/gotham-ultra.otf') format('opentype');
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: 'Gotham-Bold';
  src: url('/fonts/gotham-bold-regular.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

body {
  font-weight: 400;
  font-size: 16px;
  line-height: 30px;
  color: #fff; 
  background: #333;
}

a {
  color: inherit;
  transition: all 0.3s ease 0s; }
  a:hover, a:focus {
    color: #ababab;
    text-decoration: none;
    outline: 0 none; }

h1, h2, h3,
h4, h5, h6 {
  font-family: 'Gotham-Bold';
  text-transform: uppercase;
  color: rgb(156,123,165);
}
  

p {
  margin-bottom: 20px; }
  p:last-child {
    margin-bottom: 0; }

hr {
  border-top: 1px solid rgba(255,255,255,.2);
}

.card {
  background-color: #1e2530;
}

/*
 * Selection color
 */

::selection {
  background-color: rgb(156,123,165);
  color: #fff; }


/* Button Overrides */
.btn {
  border-radius: 5px;
  padding-left: 3.25rem;
  padding-right: 3.25rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  white-space: nowrap;
}
.btn-main {
  background-color: #0272bc;
  color: white;
}
.btn-main:hover {
  color: white;
  -webkit-filter: brightness(95%);
          filter: brightness(95%);
}
.btn-white {
  background-color: white;
  color: #0272bc;
}
.btn-white:hover {
  color: #0272bc;
  -webkit-filter: brightness(95%);
          filter: brightness(95%);
}
.terms-conditions-btn {
  padding: 0;
}
.logo {
  margin-bottom:20px;
  width: 100%;
}
.logo img {
  max-width: 100%;
}

/* General overrides of the framework */
* {
  font-family: 'Gotham Rounded', 'Montserrat';
}
a {
  color: #0272bc;
}
@media (min-width: 375px) {
  a {
  }
  .btn {
  }
}
@media (min-width: 425px) {
}
@media (min-width: 576px) {
}
@media (min-width: 768px) {
  .btn {
    font-size: 18px;
    line-height: 18px;
    letter-spacing: -0.43px;
  }
  h1, h2, h3, h4, h5, h6 {
    letter-spacing: -0.43px;
  }
}
@media (min-width: 992px) {
}
@media (min-width: 1200px) {
  a {
    font-size: 18px;
  }
}
@media (min-width: 1500px) {
}

/* Reuseable for all inputs with custom border */
.inputs-border {
  border: 1px solid #777777;
  padding: 15px;
  border-radius: 20px;
}

.dark {
  background-color: rgba(12,15,21,0.6);
}
  .dark .page-home .overlay {
    background-color: rgba(14, 17, 24, 0.97); }
  .dark .page-title h2 {
    color: #ffffff; }
  .dark .page-title .title-bg {
    color: rgba(70, 88, 124, 0.06); }
  .dark .section-title h3::before {
    background-color: #fff; }
  .dark .service-hex .st0 {
    fill: transparent; }
  .dark .service-hex:hover .st0 {
    fill: #fff; }
  .dark .testimonials .text,
  .dark .btn-border {
    border-color: #1e2530; }

/*
 *  Reset bootstrap's default style
 */
.form-control::-webkit-input-placeholder,
::-webkit-input-placeholder {
  opacity: 1;
  color: inherit; }

.form-control:-moz-placeholder,
:-moz-placeholder {
  /* Firefox 18- */
  opacity: 1;
  color: inherit; }

.form-control::-moz-placeholder,
::-moz-placeholder {
  /* Firefox 19+ */
  opacity: 1;
  color: inherit; }

.form-control:-ms-input-placeholder,
:-ms-input-placeholder {
  opacity: 1;
  color: inherit; }

button, input, select,
textarea, label {
  font-weight: 400; }

.btn {
  transition: all 0.3s ease 0s; }
  .btn:hover, .btn:focus, .btn:active:focus {
    outline: 0 none; }

.btn-primary {
  background-color: rgb(156,123,165);
  border: 0;
  font-weight: 700;
  height: 48px;
  line-height: 50px;
  padding: 0 20px;
  text-transform: uppercase; }
  .btn-primary:hover, .btn-primary:focus, .btn-primary:active, .btn-primary:active:focus {
    background-color: rgb(126,93,135); }

.btn-border {
  border: 1px solid #d7d8db;
  display: inline-block;
  padding: 7px; }

/*
 *  CSS Helper Class
 */
.clear:before, .clear:after {
  content: " ";
  display: table; }

.clear:after {
  clear: both; }

.pt-table {
  width: 100%;
}

.pt-tablecell {}

.overlay {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%; }

.relative {
  position: relative; }

.primary,
.link:hover {
  color: #FA6862; }

.no-gutter {
  margin-left: 0;
  margin-right: 0; }
  .no-gutter > [class^="col-"] {
    padding-left: 0;
    padding-right: 0; }

.flex {
  display: -moz-flex;
  display: flex; }

.flex-middle {
  -moz-align-items: center;
  align-items: center; }

.space-between {
  -moz-justify-content: space-between;
  justify-content: space-between; }

.nicescroll-cursors {
  background: #FA6862 !important; }

/*------------------------------------------------
	Start Styling
-------------------------------------------------*/

.page-close {
  font-size: 30px;
  position: absolute;
  right: 30px;
  top: 30px;
  z-index: 100; }

.page-title {
  margin-bottom: 40px; }
  .page-title img {
    margin-bottom: 20px; 
    height:200px;}
  .page-title h2 {
    font-size: 68px;
    margin-bottom: 25px;
    position: relative;
    z-index: 0;
    font-weight: 900;
    text-transform: uppercase; }
  .page-title p {
    font-size: 16px; }
  .page-title .title-bg {
    color: rgba(30, 37, 48, 0.07);
    font-size: 158px;
    left: 0;
    letter-spacing: 10px;
    line-height: 0.7;
    position: absolute;
    right: 0;
    top: 50%;
    z-index: -1;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%); }

.section-title {
  margin-bottom: 20px; }
  .section-title h3 {
    display: inline-block;
    position: relative; }
    .section-title h3::before, .section-title h3::after {
      content: "";
      height: 2px;
      position: absolute;
      bottom: 8px;
      left: calc(100% + 14px); }
    .section-title h3::before {
      background-color: #1e2530;
      width: 96px;
      bottom: 14px; }
    .section-title h3::after {
      background-color: #FA6862;
      width: 73px; }
  .section-title.light h3 {
    color: #fff; }
    .section-title.light h3::before {
      background-color: #fff; }

.page-nav {
  bottom: 40px;
  left: 0;
  position: absolute;
  right: 0; }
  .page-nav span {
    font-size: 14px;
    font-weight: 500;
    line-height: 0.9;
    text-transform: uppercase; }

/*------------------------------------------------
    Home Page
-------------------------------------------------*/
.page-home {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover; }
  .page-home .overlay {
    background-color: rgba(255, 255, 255, 0.93); }

/* End of container */

.hexagon-item {
  cursor: pointer;
  width: 200px;
  height: 173.20508px;
  float: left;
  margin-left: -29px;
  z-index: 0;
  position: relative;
  -webkit-transform: rotate(30deg);
  transform: rotate(30deg); }
  .hexagon-item:first-child {
    margin-left: 0; }
  .hexagon-item:hover {
    z-index: 1; }
    .hexagon-item:hover .hex-item:last-child {
      opacity: 1;
      -webkit-transform: scale(1.3);
      transform: scale(1.3); }
    .hexagon-item:hover .hex-item:first-child {
      opacity: 1;
      -webkit-transform: scale(1.2);
      transform: scale(1.2); }
      .hexagon-item:hover .hex-item:first-child div:before,
      .hexagon-item:hover .hex-item:first-child div:after {
        height: 15px; }
    .hexagon-item:hover .hex-item div::before,
    .hexagon-item:hover .hex-item div::after {
      background-color: rgb(156,123,165); }
    .hexagon-item:hover .hex-content svg {
      -webkit-transform: scale(0.97);
      transform: scale(0.97); }

.page-home .hexagon-item:nth-last-child(1),
.page-home .hexagon-item:nth-last-child(2),
.page-home .hexagon-item:nth-last-child(3) {
  -webkit-transform: rotate(30deg) translate(87px, -80px);
  transform: rotate(30deg) translate(87px, -80px); }

.hex-item {
  position: absolute;
  top: 0;
  left: 50px;
  width: 100px;
  height: 173.20508px; }
  .hex-item:first-child {
    z-index: 0;
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
    transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1); }
  .hex-item:last-child {
    transition: all 0.3s cubic-bezier(0.19, 1, 0.22, 1);
    z-index: 1; }
  .hex-item div {
    box-sizing: border-box;
    position: absolute;
    top: 0;
    width: 100px;
    height: 173.20508px;
    -webkit-transform-origin: center center;
    transform-origin: center center; }
    .hex-item div::before, .hex-item div::after {
      background-color: #1e2530;
      content: "";
      position: absolute;
      width: 100%;
      height: 3px;
      transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1) 0s; }
    .hex-item div:before {
      top: 0; }
    .hex-item div:after {
      bottom: 0; }
    .hex-item div:nth-child(1) {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg); }
    .hex-item div:nth-child(2) {
      -webkit-transform: rotate(60deg);
      transform: rotate(60deg); }
    .hex-item div:nth-child(3) {
      -webkit-transform: rotate(120deg);
      transform: rotate(120deg); }

.hex-content {
  color: #fff;
  display: block;
  height: 180px;
  margin: 0 auto;
  position: relative;
  text-align: center;
  -webkit-transform: rotate(-30deg);
          transform: rotate(-30deg);
  width: 156px; }
  .hex-content .hex-content-inner {
    left: 50%;
    margin: -3px 0 0 2px;
    position: absolute;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%); }
  .hex-content .disabled {
    color: #787878; }
  .hex-content .icon {
    display: block;
    font-size: 36px;
    line-height: 30px;
    margin-bottom: 11px; }
  .hex-content .title {
    display: block;
    font-size: 18px;
    letter-spacing: 1px;
    line-height: 24px;
    text-transform: uppercase;
    font-family: 'Gotham-Bold' }
  .hex-content svg {
    left: -7px;
    position: absolute;
    top: -13px;
    -webkit-transform: scale(0.87);
            transform: scale(0.87);
    z-index: -1;
    transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1) 0s; }
  .hex-content:hover {
    color: #fff; }

.tm-hex {
  position: relative;
  width: 170px;
  height: 98.15px;
  margin: 49.07px 0;
  background-size: auto 182.4427px;
  background-position: center;
  border-left: solid 6px #1e2530;
  border-right: solid 6px #1e2530; }
  .tm-hex::after {
    content: "";
    position: absolute;
    top: 3.4641px;
    left: 0;
    width: 158.0000px;
    height: 91.2213px;
    z-index: 2;
    background: inherit; }
  .tm-hex .hexTop,
  .tm-hex .hexBottom {
    position: absolute;
    z-index: 1;
    width: 120.21px;
    height: 120.21px;
    overflow: hidden;
    background: inherit;
    left: 18.90px;
    -webkit-transform: scaleY(0.5774) rotate(-45deg);
    transform: scaleY(0.5774) rotate(-45deg); }
    .tm-hex .hexTop::before, .tm-hex .hexTop::after,
    .tm-hex .hexBottom::before,
    .tm-hex .hexBottom::after {
      content: "";
      position: absolute;
      width: 158.0000px;
      height: 91.22134253196087px;
      background: inherit;
      -webkit-transform-origin: 0 0;
      transform-origin: 0 0;
      -webkit-transform: rotate(45deg) scaleY(1.7321) translateY(-45.6107px);
      transform: rotate(45deg) scaleY(1.7321) translateY(-45.6107px); }
  .tm-hex .hexTop {
    top: -60.1041px;
    border-top: solid 8.4853px #1e2530;
    border-right: solid 8.4853px #1e2530; }
    .tm-hex .hexTop::after {
      background-position: center top; }
  .tm-hex .hexBottom {
    bottom: -60.1041px;
    border-bottom: solid 8.4853px #1e2530;
    border-left: solid 8.4853px #1e2530; }
    .tm-hex .hexBottom::after {
      background-position: center bottom; }

.pt-table.desktop-768 .pt-tablecell {
  padding-bottom: 100px;
  padding-top: 40px; }



@media only screen and (max-width: 767px) {
  .hexagon-item {
      float: none;
      margin: 0 auto 50px !important;
  }
  .page-home .hexagon-item:nth-last-child(1), .page-home .hexagon-item:nth-last-child(2), .page-home .hexagon-item:nth-last-child(3) {
    -webkit-transform: rotate(30deg) translate(0px, 0px);
    transform: rotate(30deg) translate(0px, 0px);
  }
}

.client-type .cards-form-group {
  margin-bottom: 2rem;
  margin-top: 2rem;
}
.client-type .card {
  border: none;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}
.client-type .card:hover {
  -webkit-transform: scale(1.05, 1.05);
          transform: scale(1.05, 1.05);
}
.client-type .card::after {
  content: '';
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 100%;
  opacity: 0;
  border-radius: 5px;
  opacity: 0;
  transition: opacity .3s ease-in-out;
}
.client-type .card:hover::after {
  opacity: 1;
}
.client-type .card.selected {
  -webkit-transform: scale(1.05, 1.05);
          transform: scale(1.05, 1.05);
  box-shadow: 0 1px 6px rgba(0, 0, 0, 0.12), 0 1px 4px rgba(0, 0, 0, 0.12);
}
.client-type .card-body {
  padding: 0;
  margin-top: 7px;
  margin-left: 7px;
  margin-right: 7px;
  border-top-style: solid;
  border-top-color: #0272bc;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
}
.client-type .cards-form-group button {
  padding: 1.25rem;
  width: 100%;
  height: 100%;
  color: #333333;
}
.client-type .cards-form-group button:hover,
.client-type .cards-form-group button:focus {
  text-decoration: none;
}
.client-type .cards-form-group img {
  min-width: 100%;
  max-height: 100px;
}
@media (min-width: 375px) {
}
@media (min-width: 425px) {
}
@media (min-width: 576px) {
}
@media (min-width: 768px) {
  .client-type .modal-body {
    max-width: 525px;
    margin-left: auto;
    margin-right: auto;
  }
}
@media (min-width: 992px) {
}
@media (min-width: 1200px) {
  .client-type .modal-body {
    max-width: 700px;
  }
}
@media (min-width: 1440px) {
  .client-type .modal-body {
    max-width: 800px;
  }
}

.trip-type .card {
  border: none;
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
}
.trip-type .list-group-item:hover {
  -webkit-filter: brightness(95%);
          filter: brightness(95%);
  cursor: pointer;
}
.trip-type .list-group-item.active {
  background-color: #0272bc;
}
.trip-location .images {
  -webkit-animation: showContent 1s ease-in-out;
          animation: showContent 1s ease-in-out;
  padding: 25px;
  padding-bottom: 0;
}
@-webkit-keyframes showContent {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes showContent {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.trip-location .images .row {
  justify-content: center;
  align-items: center;
}
.trip-location .images button {
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: #212529;
}
.trip-location .images button:hover,
.trip-location .images button:focus {
  -webkit-transform: scale(1.1, 1.1);
          transform: scale(1.1, 1.1);
  color: #212529;
  text-decoration: none;
}
.trip-location button::after {
  content: '';
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 100%;
  opacity: 0;
  border-radius: 5px;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}
.trip-location button:hover::after {
  opacity: 1;
}
.trip-location .images button.active {
  -webkit-transform: scale(1.1, 1.1);
          transform: scale(1.1, 1.1);
  box-shadow: 0 1px 6px rgba(0, 0, 0, 0.12), 0 1px 4px rgba(0, 0, 0, 0.12);
}
.trip-location .images p {
  margin-bottom: 0;
  padding-top: 7px;
}
.trip-location .images .img-col {
  display: flex;
  justify-content: center;
}
.trip-location .images button {
  margin-bottom: 2rem;
}
.trip-location .images img {
  max-height: 170px;
  min-height: 170px;
}
.trip-location .images [alt='San José'] {
  margin-bottom: 0;
}
.trip-location .images [alt='Oakland'] {
  max-height: 140px;
  min-height: 140px;
}
.trip-location .location-selected {
  color: #495057;
  padding-left: 0.5rem;
  padding-top: 1rem;
  margin-bottom: 1rem;
}
.trip-location .location-selected h5 {
  padding-bottom: 1rem;
}
.trip-location .location {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  color: #495057;
  margin-bottom: 1rem;
  display: flex;
  justify-content: space-between;
}
.trip-location .location:last-of-type {
  margin-bottom: 1rem;
}
.trip-location h5,
.trip-location h6 {
  margin-bottom: 0;
}
.trip-location h6 {
  margin-top: 0.2rem;
}
.trip-location .location button {
  margin-right: 1rem;
}

@media (min-width: 375px) {
}
@media (min-width: 425px) {
}
@media (min-width: 576px) {
}
@media (min-width: 768px) {
  .client-type .modal-body {
    max-width: 525px;
    margin-left: auto;
    margin-right: auto;
  }
}
@media (min-width: 992px) {
}
@media (min-width: 1200px) {
  .client-type .modal-body {
    max-width: 700px;
  }
}
@media (min-width: 1440px) {
  .client-type .modal-body {
    max-width: 800px;
  }
}

.amount-of-people .custom-range {
  width: 75%;
  padding-bottom: 25px;
  padding-top: 25px;
  margin-left: auto;
  margin-right: auto;
  border: none;
}
.amount-of-people .form-control:focus {
  box-shadow: none;
}
.amount-of-people .form-control[type='number'] {
  max-width: 100px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 25px;
  border-top: none;
  border-left: none;
  border-right: none;
  border-radius: 0;
  border-bottom-color: #777777;
  border-bottom-width: 2px;
  color: #666666;
  padding-bottom: 0;
  font-size: 1.75rem;
}
.amount .number-inputs input {
}

.vehicle-type .select-form-group {
  margin-bottom: 2rem;
}
.vehicle-type .custom-select:focus {
  border-color: #0272bc;
  box-shadow: 0 0 0 0.2rem rgba(2, 114, 188, 0.5);
}
.vehicle-type .option-selection {
  color: #222222;
  margin-bottom: 2rem;
}
.vehicle-type .option-selection .col-3 {
  display: flex;
  align-items: center;
  justify-content: center;
}
.vehicle-type .option-selection p {
  margin-bottom: 0;
}
.vehicle-type .option-selection img {
  width: 100%;
}
.vehicle-type .option-selection button,
.vehicle-type .vehicle-show button {
  padding: 0;
  display: flex;
  flex-direction: column;
  color: #222222;
}
.vehicle-type .option-selection button img {
  padding-bottom: 0.5rem;
}
.vehicle-type .option-selection button:hover,
.vehicle-type .option-selection button:focus,
.vehicle-type .vehicle-show button:hover,
.vehicle-type .vehicle-show button:focus {
  opacity: 0.8;
}
.vehicle-type .vehicle .name {
}
.vehicle-type .vehicle .amount {
}
.vehicle-type .vehicle .delete {
}
@media (min-width: 375px) {
}
@media (min-width: 425px) {
}
@media (min-width: 576px) {
  .vehicle-type .number-inputs .form-group {
    height: 120px;
  }
}
@media (min-width: 768px) {
  .vehicle-type .modal-body {
    max-width: 450px;
    margin-left: auto;
    margin-right: auto;
  }
}
@media (min-width: 992px) {
  .vehicle-type .modal-body {
    max-width: 600px;
  }
  .vehicle-type .option-selection .selection-img-row {
    text-align: center;
  }
}
@media (min-width: 1200px) {
  .vehicle-type .modal-body {
    max-width: 700px;
  }
}
@media (min-width: 1440px) {
  .vehicle-type .modal-body {
    max-width: 800px;
  }
}

.details .form-label {
  color: #333333;
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.details input {
  border: none;
  padding-left: 0;
  color: #666666;
}
.details .contact-info input {
  border-bottom: #808080 1px solid;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.details .contact-info input::-webkit-input-placeholder,
.details .type-of-event-input input::-webkit-input-placeholder {
  color: rgba(132, 119, 119, 0.5);
}
.details .contact-info input::placeholder,
.details .type-of-event-input input::placeholder {
  color: rgba(132, 119, 119, 0.5);
}
.details .inputs-border {
  margin-bottom: 2rem;
}
@media (min-width: 375px) {
}
@media (min-width: 425px) {
}
@media (min-width: 576px) {
}
@media (min-width: 768px) {
  .details .form-label {
    font-size: 18px;
    line-height: 18px;
    letter-spacing: .1px;
  }
  .details input {
    font-size: 22px;
    line-height: 22px;
    letter-spacing: .22px;
  }
  .details .modal-body {
    max-width: 550px;
    margin-left: auto;
    margin-right: auto;
  }
}
@media (min-width: 992px) {
  .details .modal-body {
    max-width: 650px;
  }
}
@media (min-width: 1200px) {
  .details .modal-body {
    max-width: 750px;
  }
}
@media (min-width: 1440px) {
  .details .modal-body {
    max-width: 850px;
  }
}

.quote {
}
.quote .hello {
  color: #666666;
  margin-bottom: 1.5rem;
}
.quote .quote-in-dollars {
  text-align: center;
  margin-bottom: 1.5rem;
}
.quote .quote-in-dollars h6 {
  color: #333333;
}
.quote .quote-in-dollars h5 {
  color: #0272bc;
  font-size: 1.5rem;
}
.quote .checkmark-with-data .col {
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
}
.quote .checkmark-with-data .col p {
  margin-bottom: 0;
  margin-left: 2rem;
  color: #666666;
}
.quote .radio-btn-selection {
  color: #0272bc;
  margin-bottom: 2rem;
  margin-top: 2rem;
}
.quote .final-info {
  text-align: center;
  color: #666666;
}
.quote .selection-img .col {
  text-align: center;
}
.quote .selection-img img {
  width: 80%;
}
.quote .modal-footer {
  display: flex;
  flex-direction: column;
}
.quote .alert {
  margin-bottom: 0;
  padding-bottom: 0;
}
@media (min-width: 375px) {
}
@media (min-width: 425px) {
  .quote .selection-img img {
    width: 60%;
  }
}
@media (min-width: 576px) {
  .quote .selection-img img {
    width: 35%;
  }
}
@media (min-width: 768px) {
  .quote .modal-body {
    max-width: 450px;
    margin-left: auto;
    margin-right: auto;
  }
  .quote .hello h5 {
    font-size: 22px;
    line-height: 22px;
    letter-spacing: 0.12px;
  }
  .quote .quote-in-dollars h6 {
    font-size: 18px;
    line-height: 18px;
    letter-spacing: 0.1px;
  }
  .quote .quote-in-dollars h5 {
    font-size: 30px;
    line-height: 30px;
    letter-spacing: 0.17px;
  }
  .quote .checkmark-with-data p {
    font-size: 22px;
    line-height: 22px;
    letter-spacing: 0.17px;
  }
  .quote .radio-btn-selection {
    width: 75%;
    margin-left: auto;
    margin-right: auto;
  }
  .quote .radio-btn-selection p {
    font-size: 22px;
    line-height: 22px;
    letter-spacing: 0.17px;
  }
  .quote .final-info p {
    font-size: 18px;
    line-height: 18px;
    letter-spacing: 0.1px;
  }
  .quote .radio-btn-selection .selection-img {
    text-align: center;
  }
  .quote .radio-btn-selection img {
    width: 50%;
  }
}
@media (min-width: 992px) {
  .quote .modal-body {
    max-width: 600px;
  }
}
@media (min-width: 1200px) {
  .quote .modal-body {
    max-width: 700px;
  }
}
@media (min-width: 1440px) {
  .quote .modal-body {
    max-width: 800px;
  }
  .quote .radio-btn-selection img {
    width: 60%;
  }
}

.main-modal.modal {
  display: flex !important;
  align-items: center;
  margin-top: -60px;
}
.main-modal .modal-dialog {
  max-width: 300px;
  margin-left: auto;
  margin-right: auto;
  /* overflow-y: scroll; */
}
.modal-header {
  border-bottom: none;
  max-width: 80%;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}
.main-modal .modal-content {
  padding-top: 25px;
  padding-bottom: 25px;
}
.main-modal .modal-title {
  margin-left: auto;
  margin-right: auto;
  color: #0272bc;
}
.main-modal .modal-header .close {
  position: absolute;
  right: 15px;
  top: 15px;
}
.main-modal .go-back {
  position: absolute;
  left: 15px;
  top: 15px;
  border: none;
  background: white;
}
.main-modal .go-back img {
  width: 20px;
}
.main-modal .modal-body {
  margin-left: auto;
  margin-right: auto;
}
.main-modal .modal-footer {
  border-top: 0;
}
.main-modal .modal-footer button {
  margin-left: auto;
  margin-right: auto;
}
@media (min-width: 375px) {
  .main-modal .modal-dialog {
    max-width: 350px;
  }
}
@media (min-width: 425px) {
}
@media (min-width: 576px) {
  .main-modal .modal-dialog {
    max-width: 500px;
  }
}
@media (min-width: 768px) {
  .main-modal.modal {
    display: block !important;
    margin-top: 0;
  }
  .main-modal .modal-title {
    font-size: 30px;
    line-height: 30px;
    letter-spacing: .17px;
  }
  .main-modal .continue-btn {
    font-size: 30px;
    line-height: 30px;
    letter-spacing: -0.65px;
    padding-left: 5rem;
    padding-right: 5rem;
  }
  .main-modal .modal-dialog {
    max-width: 600px;
  }
}
@media (min-width: 992px) {
  .main-modal .modal-dialog {
    max-width: 700px;
  }
}
@media (min-width: 1200px) {
  .main-modal .modal-dialog {
    max-width: 800px;
  }
}
@media (min-width: 1440px) {
  .main-modal .modal-dialog {
    max-width: 900px;
  }
}

.navbar {
  padding-top: 25px;
  z-index: 20;
  position: absolute;
  width: 100%;
}
.navbar .navbar-nav .nav-link {
  color: white;
}
.navbar .navbar-nav .nav-link:hover,
.navbar .navbar-nav .nav-link:focus {
  color: lightgrey;
}
.navbar .navbar-toggler {
  border-color: white;
}
.navbar .navbar-toggler-icon {
  -webkit-filter: invert(100%);
          filter: invert(100%);
}
@media (min-width: 375px) {
}
@media (min-width: 425px) {
}
@media (min-width: 576px) {
}
@media (min-width: 768px) {
  .navbar .navbar-nav .nav-link {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
  .navbar .navbar-nav {
    margin-left: auto;
    margin-right: auto;
  }
  .navbar .navbar-nav .nav-link {
    font-size: 18px;
    line-height: 18px;
    letter-spacing: -0.42px;
  }
}
@media (min-width: 992px) {
  .navbar {
    padding-left: 5%;
  }
  .navbar .start-quote-container {
    padding-right: 5%;
  }
}
@media (min-width: 1200px) {
  .navbar-brand img {
    width: 158px;
    height: 69px;
  }
}
@media (min-width: 1500px) {
}

.services {
  margin-top: 100px;
  padding-bottom: 75px;
}
.services .title {
  margin-bottom: 15px;
  text-align: center;
}
.services h1,
.services h5,
.services .terms-conditions p {
  font-weight: bold;
}
.services .service {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}
.services .service img {
  margin-top: 3rem;
  margin-bottom: 2rem;
}
.services .service h5 {
  margin-bottom: 2rem;
}
.services .terms-conditions {
  margin-top: 50px;
  text-align: center;
}
.services .terms-conditions p {
  font-size: 1.25rem;
  margin: 0;
}

@media (min-width: 375px) {
}
@media (min-width: 425px) {
}
@media (min-width: 576px) {
}
@media (min-width: 768px) {
  .services h1 {
    font-size: 48px;
    line-height: 48px;
  }
  .services h5 {
    font-size: 22px;
    line-height: 22px;
    letter-spacing: -0.48px;
  }
  .services .service p {
    font-size: 16px;
    line-height: 16px;
    letter-spacing: -0.35px;
  }
  .services p {
    font-size: 22px;
    line-height: 22px;
    letter-spacing: -0.48px;
  }
}
@media (min-width: 992px) {
}
@media (min-width: 1200px) {
  .services.container {
    max-width: 1200px;
  }
  .services .service {
    padding-right: 40px;
    padding-left: 40px;
  }
}
@media (min-width: 1500px) {
}

.terms-conditions .modal-dialog {
  min-width: 80%;
}
.terms-conditions .modal-dialog * {
  color: rgba(0, 0, 0, 0.8);
}
.terms-conditions li {
  padding-bottom: 10px;
}

.gallery-modal img {
  max-width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
}
/* Particular to section (fleet and clients) css is edited in either Fleet.css or Clients.css */
.image-with-text h3 {
  margin-bottom: 2rem;
  font-weight: bold;
}
.image-with-text .img-col {
  display: flex;
  justify-content: center;
  align-items: center;
}
.image-with-text img {
  width: 100%;
}
.image-with-text button {
  width: 100%;
}
.image-with-text .start-quote-margin {
  margin-bottom: 1rem;
}
@media (min-width: 375px) {
}
@media (min-width: 425px) {
}
@media (min-width: 576px) {
}
@media (min-width: 768px) {
  .fleet h3 {
    font-size: 22px;
    line-height: 22px;
    letter-spacing: -0.48px;
  }
  .fleet p {
    font-size: 16px;
    line-height: 16px;
    letter-spacing: -0.35px;
    margin-bottom: 25px;
  }
}
@media (min-width: 992px) {
  .image-with-text img {
    width: 95%;
  }
}
@media (min-width: 1200px) {
}
@media (min-width: 1500px) {
}

.fleet {
  /* This is because the slick dots are pushed very low, 
  outside of their original container */
  padding-bottom: 150px;
  padding-top: 50px;
}
.fleet h1 {
  text-align: center;
  font-weight: bold;
}
.fleet .slick-dots {
  margin-bottom: -75px;
}
.fleet .slick-dots li button:before {
  font-size: 20px;
}
.fleet .img-col {
  margin-bottom: 25px;
}
.fleet h1 {
  margin-bottom: 50px;
}
@media (min-width: 375px) {
}
@media (min-width: 425px) {
}
@media (min-width: 576px) {
}
@media (min-width: 768px) {
  .fleet h1 {
    font-size: 48px;
    line-height: 48px;
  }
  .fleet .img-col {
    margin-bottom: 0;
  }
  .fleet .slick-dots {
    margin-bottom: -25px;
  }
}
@media (min-width: 992px) {
}
@media (min-width: 1200px) {
}
@media (min-width: 1500px) {
}

.clients {
  padding-top: 75px;
  padding-bottom: 75px;
}
.clients h1 {
  text-align: center;
  padding-bottom: 50px;
}
.clients .img-container {
  padding-bottom: 50px;
  display: flex;
  flex-direction: column;
  margin: auto;
}
/* Scoped to this class */
.clients .image-with-text .text-col {
  position: relative;
  top: 50px;
  /* To compensate for pushing content down */
  padding-bottom: 50px;
}
.clients p {
  font-weight: 200;
}
.clients blockquote {
  font-weight: bold;
}
.clients .image-with-text .text-col:before {
  content: '\201F';
  position: absolute;
  top: -15px;
  font-size: 300px;
  line-height: 300px;
  color: #0272bc;
  opacity: 0.2;
}
.clients .image-with-text .img-col {
}
.clients .image-with-text .img-col img {
  border-radius: 50%;
  /* temporary */
  border: 1px lightgray solid;
}
.clients .slick-dots {
  bottom: -75px;
}
@media (min-width: 375px) {
  .clients .image-with-text .img-col img {
    width: 75%;
  }
}
@media (min-width: 425px) {
}
@media (min-width: 576px) {
}
@media (min-width: 768px) {
  .clients h1 {
    font-size: 48px;
    line-height: 48px;
  }
  .clients p {
    font-size: 27px;
    line-height: 27px;
    letter-spacing: -0.59px;
  }
  .clients blockquote {
    font-size: 22px;
    line-height: 22px;
    letter-spacing: -0.48px;
  }
  .clients .img-container {
    flex-direction: row;
  }
  .clients .img-container img {
    padding-bottom: 0;
    padding-right: 15px;
  }
  .clients .img-container img:last-child {
    padding-right: 0;
  }
  .clients .image-with-text .text-col {
    margin-left: 100px;
  }
  .clients .image-with-text .text-col:before {
    left: -60px;
    top: -45px;
  }
  .clients .image-with-text .img-col {
    /* Compensation for above code */
    margin-left: -100px;
  }
  .clients .image-with-text .img-col img {
    width: 50%;
  }
}
@media (min-width: 992px) {
  .clients p {
    max-width: 450px;
  }
  .clients .img-container img {
    padding-right: 25px;
  }
}
@media (min-width: 1200px) {
  .clients .img-container img {
    padding-right: 50px;
  }
}
@media (min-width: 1500px) {
  .clients .img-container img {
    padding-right: 75px;
  }
}

.drivers {
  padding-top: 75px;
  padding-bottom: 75px;
}
.drivers h1 {
  text-align: center;
  padding-bottom: 65px;
  font-weight: bold;
}
.drivers .img-content {
  padding-bottom: 25px;
  text-align: center;
}
.drivers img {
  width: 160px;
  height: 160px;
  border-radius: 50%;
  margin-bottom: 25px;
}
.drivers .text-content h3 {
  text-align: center;
  padding-bottom: 25px;
  font-weight: bold;
}
.drivers .text-content p {
  text-align: center;
  font-weight: bold;
}

@media (min-width: 375px) {
}
@media (min-width: 425px) {
}
@media (min-width: 576px) {
}
@media (min-width: 768px) {
  .drivers h1 {
    font-size: 48px;
    line-height: 48px;
  }
  .drivers h3 {
    font-size: 38px;
    line-height: 38px;
  }
  .drivers p, .drivers a {
    font-size: 22px;
    line-height: 34px;
  }
  .drivers img {
    margin-bottom: 100px;
  }
  .drivers .text-content h3 {
    padding-bottom: 35px;
  }
}
@media (min-width: 992px) {
  .drivers p {
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
  }
}
@media (min-width: 1200px) {
  .drivers p {
    max-width: 925px;
  }
}
@media (min-width: 1500px) {
}

.footer {
  background: #0272bc;
  padding-top: 50px;
  padding-bottom: 50px;
}
.footer .logo-col {
  padding-bottom: 25px;
}
.footer .links-col {
  padding-bottom: 25px;
}
.footer .links-col button {
  color: white;
  text-decoration: none;
  padding: 0;
}
.footer .links-col button:hover {
  -webkit-filter: brightness(90%);
          filter: brightness(90%);
}
@media (min-width: 375px) {
}
@media (min-width: 425px) {
}
@media (min-width: 576px) {
  .footer .btn-col {
    text-align: center;
  }
}
@media (min-width: 768px) {
  .footer .links-col button {
    padding-bottom: 25px;
  }
  .footer {
    padding-top: 100px;
    padding-bottom: 50px;
  }
  .footer button {
    font-size: 18px;
    line-height: 18px;
  }
  .footer button.btn-white {
    font-size: 20px;
    line-height: 20px;
  }
}
@media (min-width: 992px) {
  .footer {
  }
}
@media (min-width: 1200px) {
  .footer .logo-col img {
    position: relative;
    top: -12px;
  }
  .footer .links-col {
    margin-top: auto;
    margin-bottom: auto;
  }
}
@media (min-width: 1500px) {
}

.hexagon-menu {
  max-width:720px;
  margin:0 auto;
}
.hero {
  min-width: 100vw;
  color: white;
  text-align: center;
  position: absolute;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  height: calc(61vh + 300px);
  min-width: 100%;
  z-index: 10;
}
.hero h1,
.hero p {
  margin-bottom: 2rem;
  font-weight: bold;
  margin-left: auto;
  margin-right: auto;
}
@media (min-width: 375px) {
}
@media (min-width: 425px) {
}
@media (min-width: 576px) {
}
@media (min-width: 768px) {
  .hero h1 {
    font-size: 48px;
    line-height: 48px;
  }
  .hero p {
    font-size: 24px;
    line-height: 34px;
  }
}
@media (min-width: 992px) {
  .hero p {
    max-width: 700px;
    margin-left: auto;
    margin-right: auto;
  }
}
@media (min-width: 1200px) {
  .hero {
  }
}
@media (min-width: 1500px) {
  .hero {
    height: calc(61vh + 500px);
  }
}
@media (min-width: 2000px) {
}

/* Same height, to space evenly with video */
.main-video .video-container {
  position: fixed;
  top: 0;
  bottom: 0;
  width: 100vw !important;
  height: 100vh !important; 
  overflow: hidden;
  z-index:-5;
}

.main-video .video-container .react-player video {
  /* Make video to at least 100% wide and tall */
  min-width: 100%; 
  min-height: 100%; 
  
  /* Setting width & height to auto prevents the browser from stretching or squishing the video */
  width: auto !important;
  height: auto !important;
  
  /* Center the video */
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%,-50%);
          transform: translate(-50%,-50%);
}
.main-video .video-container .svg-rel {
  position: relative;
}
.main-video .video-container .svg-f-btm {
  bottom: 70px;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 3;
}
.main-video .video-container .svg-white {
  fill: #fff;
}
.main-video .video-container .gradient {
  background-image: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.01));
  background-position: center;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  position: absolute;
  top: 0;
  width: 100vw;
  height: 50vh;
  z-index: 1;
}
@media (min-width: 375px) {
}
@media (min-width: 425px) {
}
@media (min-width: 576px) {
  .main-video .video-container .hero {
    height: 400px;
  }
  .main-video .video-container .hero h1 {
    max-width: 500px;
  }
}
@media (min-width: 768px) {
  .main-video .video-container .hero {
    height: 550px;
  }
  .main-video .video-container .hero h1 {
    max-width: 600px;
  }
}
@media (min-width: 992px) {
  .main-video .video-container .hero {
    height: 650px;
  }
}
@media (min-width: 1500px) {
  /* Ifs video keeps expanding then I would have to go higher and higher 
     in breakpoints to prevent text from being covered */
  /* .main-video .video-container .main-video .video-container .react-player,
  .main-video .video-container .main-video .video-container .react-player iframe {
    width: 1500px !important;
    padding-bottom: 0;
    height: 850px !important;
    margin-left: auto;
    margin-right: auto;
  } */
  .main-video .video-container .svg-f-btm {
    bottom: 100px;
  }
}

.table {
    color: #fff;
}
.table th{
    font-family: 'Gotham-Bold';
    text-transform: uppercase;
    color: rgb(156,123,165);
}
.green {
    color: rgb(147,201,178);
}
.table {
    color: #fff;
}
.table th{
    font-family: 'Gotham-Bold';
    text-transform: uppercase;
    color: rgb(156,123,165);
    background: rgba(255,255,255,0.05);
    border-top: 1px solid rgba(255,255,255,.2) !important;
}
.table td {
    border-top: 1px solid rgba(255,255,255,.2) !important;
}
.avatar-container {
    text-align: center;
}
.map iframe {
    width:100%;
    height:80vh;
    border-radius:5px;
    border:0;
}
.shop iframe {
    width:100%;
    height:1500px;
    border-radius:5px;
    border:0;
}
.table {
    color: #fff;
}
.table th{
    font-family: 'Gotham-Bold';
    text-transform: uppercase;
    color: rgb(156,123,165);
}
.green {
    color: rgb(147,201,178);
}
.discord iframe {
    width:100%;
    height:500px;
    border-radius:5px;
    border:0;
}
table {
    color: #fff;
    background: rgba(255,255,255,0.05);
}
table th{
    font-family: 'Gotham-Bold';
    text-transform: uppercase;
    color: rgb(156,123,165);
    background: rgba(255,255,255,0.05);
    border-top: 1px solid rgba(255,255,255,.2) !important;
}
table td {
    padding:10px;
    width:33%;
    vertical-align:top;
    text-align:center;
    border-top: 1px solid rgba(255,255,255,.2) !important;
}
table img {
    max-width:100%;
    height:80px;
}
.vote button {
    width:100%;
    white-space: pre-wrap;
}
.green {
    color: rgb(147,201,178);
}
/* Same height, to space evenly with video */
.main-video .video-container {
  position: fixed;
  top: 0;
  bottom: 0;
  width: 100vw !important;
  height: 100vh !important; 
  overflow: hidden;
  z-index:-5;
}

.main-video .video-container .react-player video {
  /* Make video to at least 100% wide and tall */
  min-width: 100%; 
  min-height: 100%; 
  
  /* Setting width & height to auto prevents the browser from stretching or squishing the video */
  width: auto !important;
  height: auto !important;
  
  /* Center the video */
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%,-50%);
          transform: translate(-50%,-50%);
}
.main-video .video-container .svg-rel {
  position: relative;
}
.main-video .video-container .svg-f-btm {
  bottom: 70px;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 3;
}
.main-video .video-container .svg-white {
  fill: #fff;
}
.main-video .video-container .gradient {
  background-image: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.01));
  background-position: center;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  position: absolute;
  top: 0;
  width: 100vw;
  height: 50vh;
  z-index: 1;
}
@media (min-width: 375px) {
}
@media (min-width: 425px) {
}
@media (min-width: 576px) {
  .main-video .video-container .hero {
    height: 400px;
  }
  .main-video .video-container .hero h1 {
    max-width: 500px;
  }
}
@media (min-width: 768px) {
  .main-video .video-container .hero {
    height: 550px;
  }
  .main-video .video-container .hero h1 {
    max-width: 600px;
  }
}
@media (min-width: 992px) {
  .main-video .video-container .hero {
    height: 650px;
  }
}
@media (min-width: 1500px) {
  /* Ifs video keeps expanding then I would have to go higher and higher 
     in breakpoints to prevent text from being covered */
  /* .main-video .video-container .main-video .video-container .react-player,
  .main-video .video-container .main-video .video-container .react-player iframe {
    width: 1500px !important;
    padding-bottom: 0;
    height: 850px !important;
    margin-left: auto;
    margin-right: auto;
  } */
  .main-video .video-container .svg-f-btm {
    bottom: 100px;
  }
}

