.navbar {
  padding-top: 25px;
  z-index: 20;
  position: absolute;
  width: 100%;
}
.navbar .navbar-nav .nav-link {
  color: white;
}
.navbar .navbar-nav .nav-link:hover,
.navbar .navbar-nav .nav-link:focus {
  color: lightgrey;
}
.navbar .navbar-toggler {
  border-color: white;
}
.navbar .navbar-toggler-icon {
  filter: invert(100%);
}
@media (min-width: 375px) {
}
@media (min-width: 425px) {
}
@media (min-width: 576px) {
}
@media (min-width: 768px) {
  .navbar .navbar-nav .nav-link {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
  .navbar .navbar-nav {
    margin-left: auto;
    margin-right: auto;
  }
  .navbar .navbar-nav .nav-link {
    font-size: 18px;
    line-height: 18px;
    letter-spacing: -0.42px;
  }
}
@media (min-width: 992px) {
  .navbar {
    padding-left: 5%;
  }
  .navbar .start-quote-container {
    padding-right: 5%;
  }
}
@media (min-width: 1200px) {
  .navbar-brand img {
    width: 158px;
    height: 69px;
  }
}
@media (min-width: 1500px) {
}
