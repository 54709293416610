table {
    color: #fff;
    background: rgba(255,255,255,0.05);
}
table th{
    font-family: 'Gotham-Bold';
    text-transform: uppercase;
    color: rgb(156,123,165);
    background: rgba(255,255,255,0.05);
    border-top: 1px solid rgba(255,255,255,.2) !important;
}
table td {
    padding:10px;
    width:33%;
    vertical-align:top;
    text-align:center;
    border-top: 1px solid rgba(255,255,255,.2) !important;
}
table img {
    max-width:100%;
    height:80px;
}
.vote button {
    width:100%;
    white-space: pre-wrap;
}
.green {
    color: rgb(147,201,178);
}