/* Same height, to space evenly with video */
.main-video .video-container {
  position: fixed;
  top: 0;
  bottom: 0;
  width: 100vw !important;
  height: 100vh !important; 
  overflow: hidden;
  z-index:-5;
}

.main-video .video-container .react-player video {
  /* Make video to at least 100% wide and tall */
  min-width: 100%; 
  min-height: 100%; 
  
  /* Setting width & height to auto prevents the browser from stretching or squishing the video */
  width: auto !important;
  height: auto !important;
  
  /* Center the video */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
}
.main-video .video-container .svg-rel {
  position: relative;
}
.main-video .video-container .svg-f-btm {
  bottom: 70px;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 3;
}
.main-video .video-container .svg-white {
  fill: #fff;
}
.main-video .video-container .gradient {
  background-image: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.01));
  background-position: center;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  position: absolute;
  top: 0;
  width: 100vw;
  height: 50vh;
  z-index: 1;
}
@media (min-width: 375px) {
}
@media (min-width: 425px) {
}
@media (min-width: 576px) {
  .main-video .video-container .hero {
    height: 400px;
  }
  .main-video .video-container .hero h1 {
    max-width: 500px;
  }
}
@media (min-width: 768px) {
  .main-video .video-container .hero {
    height: 550px;
  }
  .main-video .video-container .hero h1 {
    max-width: 600px;
  }
}
@media (min-width: 992px) {
  .main-video .video-container .hero {
    height: 650px;
  }
}
@media (min-width: 1500px) {
  /* Ifs video keeps expanding then I would have to go higher and higher 
     in breakpoints to prevent text from being covered */
  /* .main-video .video-container .main-video .video-container .react-player,
  .main-video .video-container .main-video .video-container .react-player iframe {
    width: 1500px !important;
    padding-bottom: 0;
    height: 850px !important;
    margin-left: auto;
    margin-right: auto;
  } */
  .main-video .video-container .svg-f-btm {
    bottom: 100px;
  }
}
