.main-modal.modal {
  display: flex !important;
  align-items: center;
  margin-top: -60px;
}
.main-modal .modal-dialog {
  max-width: 300px;
  margin-left: auto;
  margin-right: auto;
  /* overflow-y: scroll; */
}
.modal-header {
  border-bottom: none;
  max-width: 80%;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}
.main-modal .modal-content {
  padding-top: 25px;
  padding-bottom: 25px;
}
.main-modal .modal-title {
  margin-left: auto;
  margin-right: auto;
  color: #0272bc;
}
.main-modal .modal-header .close {
  position: absolute;
  right: 15px;
  top: 15px;
}
.main-modal .go-back {
  position: absolute;
  left: 15px;
  top: 15px;
  border: none;
  background: white;
}
.main-modal .go-back img {
  width: 20px;
}
.main-modal .modal-body {
  margin-left: auto;
  margin-right: auto;
}
.main-modal .modal-footer {
  border-top: 0;
}
.main-modal .modal-footer button {
  margin-left: auto;
  margin-right: auto;
}
@media (min-width: 375px) {
  .main-modal .modal-dialog {
    max-width: 350px;
  }
}
@media (min-width: 425px) {
}
@media (min-width: 576px) {
  .main-modal .modal-dialog {
    max-width: 500px;
  }
}
@media (min-width: 768px) {
  .main-modal.modal {
    display: block !important;
    margin-top: 0;
  }
  .main-modal .modal-title {
    font-size: 30px;
    line-height: 30px;
    letter-spacing: .17px;
  }
  .main-modal .continue-btn {
    font-size: 30px;
    line-height: 30px;
    letter-spacing: -0.65px;
    padding-left: 5rem;
    padding-right: 5rem;
  }
  .main-modal .modal-dialog {
    max-width: 600px;
  }
}
@media (min-width: 992px) {
  .main-modal .modal-dialog {
    max-width: 700px;
  }
}
@media (min-width: 1200px) {
  .main-modal .modal-dialog {
    max-width: 800px;
  }
}
@media (min-width: 1440px) {
  .main-modal .modal-dialog {
    max-width: 900px;
  }
}
