.amount-of-people .custom-range {
  width: 75%;
  padding-bottom: 25px;
  padding-top: 25px;
  margin-left: auto;
  margin-right: auto;
  border: none;
}
.amount-of-people .form-control:focus {
  box-shadow: none;
}
.amount-of-people .form-control[type='number'] {
  max-width: 100px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 25px;
  border-top: none;
  border-left: none;
  border-right: none;
  border-radius: 0;
  border-bottom-color: #777777;
  border-bottom-width: 2px;
  color: #666666;
  padding-bottom: 0;
  font-size: 1.75rem;
}
.amount .number-inputs input {
}
