.quote {
}
.quote .hello {
  color: #666666;
  margin-bottom: 1.5rem;
}
.quote .quote-in-dollars {
  text-align: center;
  margin-bottom: 1.5rem;
}
.quote .quote-in-dollars h6 {
  color: #333333;
}
.quote .quote-in-dollars h5 {
  color: #0272bc;
  font-size: 1.5rem;
}
.quote .checkmark-with-data .col {
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
}
.quote .checkmark-with-data .col p {
  margin-bottom: 0;
  margin-left: 2rem;
  color: #666666;
}
.quote .radio-btn-selection {
  color: #0272bc;
  margin-bottom: 2rem;
  margin-top: 2rem;
}
.quote .final-info {
  text-align: center;
  color: #666666;
}
.quote .selection-img .col {
  text-align: center;
}
.quote .selection-img img {
  width: 80%;
}
.quote .modal-footer {
  display: flex;
  flex-direction: column;
}
.quote .alert {
  margin-bottom: 0;
  padding-bottom: 0;
}
@media (min-width: 375px) {
}
@media (min-width: 425px) {
  .quote .selection-img img {
    width: 60%;
  }
}
@media (min-width: 576px) {
  .quote .selection-img img {
    width: 35%;
  }
}
@media (min-width: 768px) {
  .quote .modal-body {
    max-width: 450px;
    margin-left: auto;
    margin-right: auto;
  }
  .quote .hello h5 {
    font-size: 22px;
    line-height: 22px;
    letter-spacing: 0.12px;
  }
  .quote .quote-in-dollars h6 {
    font-size: 18px;
    line-height: 18px;
    letter-spacing: 0.1px;
  }
  .quote .quote-in-dollars h5 {
    font-size: 30px;
    line-height: 30px;
    letter-spacing: 0.17px;
  }
  .quote .checkmark-with-data p {
    font-size: 22px;
    line-height: 22px;
    letter-spacing: 0.17px;
  }
  .quote .radio-btn-selection {
    width: 75%;
    margin-left: auto;
    margin-right: auto;
  }
  .quote .radio-btn-selection p {
    font-size: 22px;
    line-height: 22px;
    letter-spacing: 0.17px;
  }
  .quote .final-info p {
    font-size: 18px;
    line-height: 18px;
    letter-spacing: 0.1px;
  }
  .quote .radio-btn-selection .selection-img {
    text-align: center;
  }
  .quote .radio-btn-selection img {
    width: 50%;
  }
}
@media (min-width: 992px) {
  .quote .modal-body {
    max-width: 600px;
  }
}
@media (min-width: 1200px) {
  .quote .modal-body {
    max-width: 700px;
  }
}
@media (min-width: 1440px) {
  .quote .modal-body {
    max-width: 800px;
  }
  .quote .radio-btn-selection img {
    width: 60%;
  }
}
