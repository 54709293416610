@font-face {
  font-family: 'Gotham-Light';
  src: url('/fonts/gotham-light.otf') format('opentype');
  font-weight: 200;
  font-style: normal;
}
@font-face {
  font-family: 'Gotham-Medium';
  src: url('/fonts/gotham-medium.otf') format('opentype');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Gotham-Ultra';
  src: url('/fonts/gotham-ultra.otf') format('opentype');
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: 'Gotham-Bold';
  src: url('/fonts/gotham-bold-regular.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

body {
  font-weight: 400;
  font-size: 16px;
  line-height: 30px;
  color: #fff; 
  background: #333;
}

a {
  color: inherit;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s; }
  a:hover, a:focus {
    color: #ababab;
    text-decoration: none;
    outline: 0 none; }

h1, h2, h3,
h4, h5, h6 {
  font-family: 'Gotham-Bold';
  text-transform: uppercase;
  color: rgb(156,123,165);
}
  

p {
  margin-bottom: 20px; }
  p:last-child {
    margin-bottom: 0; }

hr {
  border-top: 1px solid rgba(255,255,255,.2);
}

.card {
  background-color: #1e2530;
}

/*
 * Selection color
 */
::-moz-selection {
  background-color: rgb(156,123,165);
  color: #fff; }

::selection {
  background-color: rgb(156,123,165);
  color: #fff; }


/* Button Overrides */
.btn {
  border-radius: 5px;
  padding-left: 3.25rem;
  padding-right: 3.25rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  white-space: nowrap;
}
.btn-main {
  background-color: #0272bc;
  color: white;
}
.btn-main:hover {
  color: white;
  filter: brightness(95%);
}
.btn-white {
  background-color: white;
  color: #0272bc;
}
.btn-white:hover {
  color: #0272bc;
  filter: brightness(95%);
}
.terms-conditions-btn {
  padding: 0;
}
.logo {
  margin-bottom:20px;
  width: 100%;
}
.logo img {
  max-width: 100%;
}

/* General overrides of the framework */
* {
  font-family: 'Gotham Rounded', 'Montserrat';
}
a {
  color: #0272bc;
}
@media (min-width: 375px) {
  a {
  }
  .btn {
  }
}
@media (min-width: 425px) {
}
@media (min-width: 576px) {
}
@media (min-width: 768px) {
  .btn {
    font-size: 18px;
    line-height: 18px;
    letter-spacing: -0.43px;
  }
  h1, h2, h3, h4, h5, h6 {
    letter-spacing: -0.43px;
  }
}
@media (min-width: 992px) {
}
@media (min-width: 1200px) {
  a {
    font-size: 18px;
  }
}
@media (min-width: 1500px) {
}

/* Reuseable for all inputs with custom border */
.inputs-border {
  border: 1px solid #777777;
  padding: 15px;
  border-radius: 20px;
}

.dark {
  background-color: rgba(12,15,21,0.6);
}
  .dark .page-home .overlay {
    background-color: rgba(14, 17, 24, 0.97); }
  .dark .page-title h2 {
    color: #ffffff; }
  .dark .page-title .title-bg {
    color: rgba(70, 88, 124, 0.06); }
  .dark .section-title h3::before {
    background-color: #fff; }
  .dark .service-hex .st0 {
    fill: transparent; }
  .dark .service-hex:hover .st0 {
    fill: #fff; }
  .dark .testimonials .text,
  .dark .btn-border {
    border-color: #1e2530; }

/*
 *  Reset bootstrap's default style
 */
.form-control::-webkit-input-placeholder,
::-webkit-input-placeholder {
  opacity: 1;
  color: inherit; }

.form-control:-moz-placeholder,
:-moz-placeholder {
  /* Firefox 18- */
  opacity: 1;
  color: inherit; }

.form-control::-moz-placeholder,
::-moz-placeholder {
  /* Firefox 19+ */
  opacity: 1;
  color: inherit; }

.form-control:-ms-input-placeholder,
:-ms-input-placeholder {
  opacity: 1;
  color: inherit; }

button, input, select,
textarea, label {
  font-weight: 400; }

.btn {
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s; }
  .btn:hover, .btn:focus, .btn:active:focus {
    outline: 0 none; }

.btn-primary {
  background-color: rgb(156,123,165);
  border: 0;
  font-weight: 700;
  height: 48px;
  line-height: 50px;
  padding: 0 20px;
  text-transform: uppercase; }
  .btn-primary:hover, .btn-primary:focus, .btn-primary:active, .btn-primary:active:focus {
    background-color: rgb(126,93,135); }

.btn-border {
  border: 1px solid #d7d8db;
  display: inline-block;
  padding: 7px; }

/*
 *  CSS Helper Class
 */
.clear:before, .clear:after {
  content: " ";
  display: table; }

.clear:after {
  clear: both; }

.pt-table {
  width: 100%;
}

.pt-tablecell {}

.overlay {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%; }

.relative {
  position: relative; }

.primary,
.link:hover {
  color: #FA6862; }

.no-gutter {
  margin-left: 0;
  margin-right: 0; }
  .no-gutter > [class^="col-"] {
    padding-left: 0;
    padding-right: 0; }

.flex {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex; }

.flex-middle {
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center; }

.space-between {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  -moz-justify-content: space-between;
  justify-content: space-between; }

.nicescroll-cursors {
  background: #FA6862 !important; }

/*------------------------------------------------
	Start Styling
-------------------------------------------------*/

.page-close {
  font-size: 30px;
  position: absolute;
  right: 30px;
  top: 30px;
  z-index: 100; }

.page-title {
  margin-bottom: 40px; }
  .page-title img {
    margin-bottom: 20px; 
    height:200px;}
  .page-title h2 {
    font-size: 68px;
    margin-bottom: 25px;
    position: relative;
    z-index: 0;
    font-weight: 900;
    text-transform: uppercase; }
  .page-title p {
    font-size: 16px; }
  .page-title .title-bg {
    color: rgba(30, 37, 48, 0.07);
    font-size: 158px;
    left: 0;
    letter-spacing: 10px;
    line-height: 0.7;
    position: absolute;
    right: 0;
    top: 50%;
    z-index: -1;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%); }

.section-title {
  margin-bottom: 20px; }
  .section-title h3 {
    display: inline-block;
    position: relative; }
    .section-title h3::before, .section-title h3::after {
      content: "";
      height: 2px;
      position: absolute;
      bottom: 8px;
      left: -webkit-calc(100% + 14px);
      left: -moz-calc(100% + 14px);
      left: calc(100% + 14px); }
    .section-title h3::before {
      background-color: #1e2530;
      width: 96px;
      bottom: 14px; }
    .section-title h3::after {
      background-color: #FA6862;
      width: 73px; }
  .section-title.light h3 {
    color: #fff; }
    .section-title.light h3::before {
      background-color: #fff; }

.page-nav {
  bottom: 40px;
  left: 0;
  position: absolute;
  right: 0; }
  .page-nav span {
    font-size: 14px;
    font-weight: 500;
    line-height: 0.9;
    text-transform: uppercase; }

/*------------------------------------------------
    Home Page
-------------------------------------------------*/
.page-home {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover; }
  .page-home .overlay {
    background-color: rgba(255, 255, 255, 0.93); }

/* End of container */

.hexagon-item {
  cursor: pointer;
  width: 200px;
  height: 173.20508px;
  float: left;
  margin-left: -29px;
  z-index: 0;
  position: relative;
  -webkit-transform: rotate(30deg);
  -moz-transform: rotate(30deg);
  -ms-transform: rotate(30deg);
  -o-transform: rotate(30deg);
  transform: rotate(30deg); }
  .hexagon-item:first-child {
    margin-left: 0; }
  .hexagon-item:hover {
    z-index: 1; }
    .hexagon-item:hover .hex-item:last-child {
      opacity: 1;
      -webkit-transform: scale(1.3);
      -moz-transform: scale(1.3);
      -ms-transform: scale(1.3);
      -o-transform: scale(1.3);
      transform: scale(1.3); }
    .hexagon-item:hover .hex-item:first-child {
      opacity: 1;
      -webkit-transform: scale(1.2);
      -moz-transform: scale(1.2);
      -ms-transform: scale(1.2);
      -o-transform: scale(1.2);
      transform: scale(1.2); }
      .hexagon-item:hover .hex-item:first-child div:before,
      .hexagon-item:hover .hex-item:first-child div:after {
        height: 15px; }
    .hexagon-item:hover .hex-item div::before,
    .hexagon-item:hover .hex-item div::after {
      background-color: rgb(156,123,165); }
    .hexagon-item:hover .hex-content svg {
      -webkit-transform: scale(0.97);
      -moz-transform: scale(0.97);
      -ms-transform: scale(0.97);
      -o-transform: scale(0.97);
      transform: scale(0.97); }

.page-home .hexagon-item:nth-last-child(1),
.page-home .hexagon-item:nth-last-child(2),
.page-home .hexagon-item:nth-last-child(3) {
  -webkit-transform: rotate(30deg) translate(87px, -80px);
  -moz-transform: rotate(30deg) translate(87px, -80px);
  -ms-transform: rotate(30deg) translate(87px, -80px);
  -o-transform: rotate(30deg) translate(87px, -80px);
  transform: rotate(30deg) translate(87px, -80px); }

.hex-item {
  position: absolute;
  top: 0;
  left: 50px;
  width: 100px;
  height: 173.20508px; }
  .hex-item:first-child {
    z-index: 0;
    -webkit-transform: scale(0.9);
    -moz-transform: scale(0.9);
    -ms-transform: scale(0.9);
    -o-transform: scale(0.9);
    transform: scale(0.9);
    -webkit-transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
    -moz-transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
    -o-transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
    transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1); }
  .hex-item:last-child {
    transition: all 0.3s cubic-bezier(0.19, 1, 0.22, 1);
    z-index: 1; }
  .hex-item div {
    box-sizing: border-box;
    position: absolute;
    top: 0;
    width: 100px;
    height: 173.20508px;
    -webkit-transform-origin: center center;
    -moz-transform-origin: center center;
    -ms-transform-origin: center center;
    -o-transform-origin: center center;
    transform-origin: center center; }
    .hex-item div::before, .hex-item div::after {
      background-color: #1e2530;
      content: "";
      position: absolute;
      width: 100%;
      height: 3px;
      -webkit-transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1) 0s;
      -moz-transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1) 0s;
      -o-transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1) 0s;
      transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1) 0s; }
    .hex-item div:before {
      top: 0; }
    .hex-item div:after {
      bottom: 0; }
    .hex-item div:nth-child(1) {
      -webkit-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -ms-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg); }
    .hex-item div:nth-child(2) {
      -webkit-transform: rotate(60deg);
      -moz-transform: rotate(60deg);
      -ms-transform: rotate(60deg);
      -o-transform: rotate(60deg);
      transform: rotate(60deg); }
    .hex-item div:nth-child(3) {
      -webkit-transform: rotate(120deg);
      -moz-transform: rotate(120deg);
      -ms-transform: rotate(120deg);
      -o-transform: rotate(120deg);
      transform: rotate(120deg); }

.hex-content {
  color: #fff;
  display: block;
  height: 180px;
  margin: 0 auto;
  position: relative;
  text-align: center;
  transform: rotate(-30deg);
  width: 156px; }
  .hex-content .hex-content-inner {
    left: 50%;
    margin: -3px 0 0 2px;
    position: absolute;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%); }
  .hex-content .disabled {
    color: #787878; }
  .hex-content .icon {
    display: block;
    font-size: 36px;
    line-height: 30px;
    margin-bottom: 11px; }
  .hex-content .title {
    display: block;
    font-size: 18px;
    letter-spacing: 1px;
    line-height: 24px;
    text-transform: uppercase;
    font-family: 'Gotham-Bold' }
  .hex-content svg {
    left: -7px;
    position: absolute;
    top: -13px;
    transform: scale(0.87);
    z-index: -1;
    -webkit-transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1) 0s;
    -moz-transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1) 0s;
    -o-transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1) 0s;
    transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1) 0s; }
  .hex-content:hover {
    color: #fff; }

.tm-hex {
  position: relative;
  width: 170px;
  height: 98.15px;
  margin: 49.07px 0;
  background-size: auto 182.4427px;
  background-position: center;
  border-left: solid 6px #1e2530;
  border-right: solid 6px #1e2530; }
  .tm-hex::after {
    content: "";
    position: absolute;
    top: 3.4641px;
    left: 0;
    width: 158.0000px;
    height: 91.2213px;
    z-index: 2;
    background: inherit; }
  .tm-hex .hexTop,
  .tm-hex .hexBottom {
    position: absolute;
    z-index: 1;
    width: 120.21px;
    height: 120.21px;
    overflow: hidden;
    background: inherit;
    left: 18.90px;
    -webkit-transform: scaleY(0.5774) rotate(-45deg);
    -moz-transform: scaleY(0.5774) rotate(-45deg);
    -ms-transform: scaleY(0.5774) rotate(-45deg);
    -o-transform: scaleY(0.5774) rotate(-45deg);
    transform: scaleY(0.5774) rotate(-45deg); }
    .tm-hex .hexTop::before, .tm-hex .hexTop::after,
    .tm-hex .hexBottom::before,
    .tm-hex .hexBottom::after {
      content: "";
      position: absolute;
      width: 158.0000px;
      height: 91.22134253196087px;
      background: inherit;
      -webkit-transform-origin: 0 0;
      -moz-transform-origin: 0 0;
      -ms-transform-origin: 0 0;
      -o-transform-origin: 0 0;
      transform-origin: 0 0;
      -webkit-transform: rotate(45deg) scaleY(1.7321) translateY(-45.6107px);
      -moz-transform: rotate(45deg) scaleY(1.7321) translateY(-45.6107px);
      -ms-transform: rotate(45deg) scaleY(1.7321) translateY(-45.6107px);
      -o-transform: rotate(45deg) scaleY(1.7321) translateY(-45.6107px);
      transform: rotate(45deg) scaleY(1.7321) translateY(-45.6107px); }
  .tm-hex .hexTop {
    top: -60.1041px;
    border-top: solid 8.4853px #1e2530;
    border-right: solid 8.4853px #1e2530; }
    .tm-hex .hexTop::after {
      background-position: center top; }
  .tm-hex .hexBottom {
    bottom: -60.1041px;
    border-bottom: solid 8.4853px #1e2530;
    border-left: solid 8.4853px #1e2530; }
    .tm-hex .hexBottom::after {
      background-position: center bottom; }

.pt-table.desktop-768 .pt-tablecell {
  padding-bottom: 100px;
  padding-top: 40px; }



@media only screen and (max-width: 767px) {
  .hexagon-item {
      float: none;
      margin: 0 auto 50px !important;
  }
  .page-home .hexagon-item:nth-last-child(1), .page-home .hexagon-item:nth-last-child(2), .page-home .hexagon-item:nth-last-child(3) {
    -webkit-transform: rotate(30deg) translate(0px, 0px);
    -moz-transform: rotate(30deg) translate(0px, 0px);
    -ms-transform: rotate(30deg) translate(0px, 0px);
    -o-transform: rotate(30deg) translate(0px, 0px);
    transform: rotate(30deg) translate(0px, 0px);
  }
}
