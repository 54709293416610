.vehicle-type .select-form-group {
  margin-bottom: 2rem;
}
.vehicle-type .custom-select:focus {
  border-color: #0272bc;
  box-shadow: 0 0 0 0.2rem rgba(2, 114, 188, 0.5);
}
.vehicle-type .option-selection {
  color: #222222;
  margin-bottom: 2rem;
}
.vehicle-type .option-selection .col-3 {
  display: flex;
  align-items: center;
  justify-content: center;
}
.vehicle-type .option-selection p {
  margin-bottom: 0;
}
.vehicle-type .option-selection img {
  width: 100%;
}
.vehicle-type .option-selection button,
.vehicle-type .vehicle-show button {
  padding: 0;
  display: flex;
  flex-direction: column;
  color: #222222;
}
.vehicle-type .option-selection button img {
  padding-bottom: 0.5rem;
}
.vehicle-type .option-selection button:hover,
.vehicle-type .option-selection button:focus,
.vehicle-type .vehicle-show button:hover,
.vehicle-type .vehicle-show button:focus {
  opacity: 0.8;
}
.vehicle-type .vehicle .name {
}
.vehicle-type .vehicle .amount {
}
.vehicle-type .vehicle .delete {
}
@media (min-width: 375px) {
}
@media (min-width: 425px) {
}
@media (min-width: 576px) {
  .vehicle-type .number-inputs .form-group {
    height: 120px;
  }
}
@media (min-width: 768px) {
  .vehicle-type .modal-body {
    max-width: 450px;
    margin-left: auto;
    margin-right: auto;
  }
}
@media (min-width: 992px) {
  .vehicle-type .modal-body {
    max-width: 600px;
  }
  .vehicle-type .option-selection .selection-img-row {
    text-align: center;
  }
}
@media (min-width: 1200px) {
  .vehicle-type .modal-body {
    max-width: 700px;
  }
}
@media (min-width: 1440px) {
  .vehicle-type .modal-body {
    max-width: 800px;
  }
}
