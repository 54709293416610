.drivers {
  padding-top: 75px;
  padding-bottom: 75px;
}
.drivers h1 {
  text-align: center;
  padding-bottom: 65px;
  font-weight: bold;
}
.drivers .img-content {
  padding-bottom: 25px;
  text-align: center;
}
.drivers img {
  width: 160px;
  height: 160px;
  border-radius: 50%;
  margin-bottom: 25px;
}
.drivers .text-content h3 {
  text-align: center;
  padding-bottom: 25px;
  font-weight: bold;
}
.drivers .text-content p {
  text-align: center;
  font-weight: bold;
}

@media (min-width: 375px) {
}
@media (min-width: 425px) {
}
@media (min-width: 576px) {
}
@media (min-width: 768px) {
  .drivers h1 {
    font-size: 48px;
    line-height: 48px;
  }
  .drivers h3 {
    font-size: 38px;
    line-height: 38px;
  }
  .drivers p, .drivers a {
    font-size: 22px;
    line-height: 34px;
  }
  .drivers img {
    margin-bottom: 100px;
  }
  .drivers .text-content h3 {
    padding-bottom: 35px;
  }
}
@media (min-width: 992px) {
  .drivers p {
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
  }
}
@media (min-width: 1200px) {
  .drivers p {
    max-width: 925px;
  }
}
@media (min-width: 1500px) {
}
