.fleet {
  /* This is because the slick dots are pushed very low, 
  outside of their original container */
  padding-bottom: 150px;
  padding-top: 50px;
}
.fleet h1 {
  text-align: center;
  font-weight: bold;
}
.fleet .slick-dots {
  margin-bottom: -75px;
}
.fleet .slick-dots li button:before {
  font-size: 20px;
}
.fleet .img-col {
  margin-bottom: 25px;
}
.fleet h1 {
  margin-bottom: 50px;
}
@media (min-width: 375px) {
}
@media (min-width: 425px) {
}
@media (min-width: 576px) {
}
@media (min-width: 768px) {
  .fleet h1 {
    font-size: 48px;
    line-height: 48px;
  }
  .fleet .img-col {
    margin-bottom: 0;
  }
  .fleet .slick-dots {
    margin-bottom: -25px;
  }
}
@media (min-width: 992px) {
}
@media (min-width: 1200px) {
}
@media (min-width: 1500px) {
}
